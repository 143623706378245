.not-found {
  position: relative;
  height: 100vh;

  &__hero {
    justify-content: center;
    height: 100vh;
    align-items: center;
  }

  :global(.hero__background) {
    opacity: 0.1;
    background: url('/icons/eb-gc-pattern.svg') center center repeat;
    background-size: 200px;
  }

  a {
    max-width: 260px;
    margin: 20px auto;
  }
}
